import { defineComponent, ref } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import NeuralTable from '@/components/Neural/NeuralTable/NeuralTable.vue';
import ActiveNeuralChanger from '@/components/Neural/ActiveNeuralChanger/ActiveNeuralChanger.vue';
import AddNeuralButton from '@/components/Neural/AddNeuralButton/AddNeuralButton.vue';
import FormAddDataset from '@/components/Forms/FormAddDataset/FormAddDataset.vue';
import DatasetTable from '@/components/Neural/DatasetTable/DatasetTable.vue';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { NEURAL_ACTIONS_TYPE } from '@/store/names/action.name';
import { useStore } from 'vuex';
import BasePaginator from '@/components/BaseComponents/BasePaginator/BasePaginator.vue';
import defaultPaginatorSettings from '@/dictionaries/defaultPaginatorSettings/defaultPaginatorSettings';
import { NEURAL_MUTATIONS_TYPE, PAGINATOR_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import { TEventPaginator } from '@/types/eventTypes';
import BasePopUp from '@/components/BaseComponents/BasePopUp/BasePopUp.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import Hint from '@/components/Hint/Hint.vue';

export default defineComponent({
  name: 'TabsNeural',

  components: {
    TabView,
    TabPanel,
    NeuralTable,
    ActiveNeuralChanger,
    AddNeuralButton,
    FormAddDataset,
    BasePaginator,
    DatasetTable,
    BasePopUp,
    BaseButton,
    Hint
  },

  props: {},

  setup() {
    const { dispatch, state, commit } = useStore();

    const onChangeNeural = (event: TEventPaginator) => {
      commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR}`, {
        size: event.rows,
        page: event.page
      });
      dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_NEURAL_LIST}`);
    };

    const onChangeDataset = (event: TEventPaginator) => {
      commit(`${MODULES_NAMES.NEURAL}/${NEURAL_MUTATIONS_TYPE.SET_DATASET_PAGINATOR}`, {
        size: event.rows,
        page: event.page
      });
      dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_DATASET_LIST}`);
    };

    const popUpRef = ref();
    const togglePopUp = () => {
      popUpRef.value.handleModal();
    };

    // const tabChanged = (value: { index: number}) => {
    //   commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_INITIAL_PAGINATOR}`, {
    //     paginator: defaultPaginatorSettings
    //   });
    //   (value.index === 1)
    //     ? dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_DATASET_LIST}`)
    //     : dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_NEURAL_LIST}`);
    // };

    return {
      state,
      // tabChanged,
      togglePopUp,
      popUpRef,
      onChangeDataset,
      onChangeNeural,
      defaultPaginatorSettings
    };
  }
});
