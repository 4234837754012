<template>
  <BaseForm
    :handleSubmit="submitForm"
    class="ui-upload-form train-form"
  >
    <template v-slot:form-content>
      <BaseDropdown
        :value="state.neural.shortDataset"
        :propValue="state.neural.shortDataset[0]"
        name="dataset"
        class="train-input"
        label="Выберите датасет для обучения нейросети"
      />
      <div class="split-line"></div>
      <div class="p-d-flex p-justify-center">
        <BaseButton label="Обучить" />
      </div>
    </template>
  </BaseForm>
</template>

<script src="./FormTrainNetwork.ts" lang="ts"/>
<style src="./FormTrainNetwork.scss" lang="scss"/>
