import { defineComponent } from 'vue';
import { object, string, number } from 'yup';
import { useForm } from 'vee-validate';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import BaseForm from '@/components/BaseComponents/BaseForm/BaseForm.vue';
import BaseInputText from '@/components/BaseComponents/BaseInputText/BaseInputText.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BaseTextArea from '@/components/BaseComponents/BaseTextArea/BaseTextArea.vue';
import BaseInputNumber from '@/components/BaseComponents/BaseInputNumber/BaseInputNumber.vue';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { NEURAL_ACTIONS_TYPE } from '@/store/names/action.name';

export default defineComponent({
  name: 'FormAddNeural',

  components: {
    BaseForm,
    BaseInputText,
    BaseButton,
    BaseTextArea,
    BaseInputNumber
  },

  setup(_, { emit }) {
    const { dispatch } = useStore();

    const schema = object({
      name: string()
        .required(FORM_DICTIONARY.REQUIRED),

      // eslint-disable-next-line @typescript-eslint/camelcase
      batch_size: number()
        .required(FORM_DICTIONARY.REQUIRED),

      // eslint-disable-next-line @typescript-eslint/camelcase
      compare_threshold: number()
        .required(FORM_DICTIONARY.REQUIRED),

      // eslint-disable-next-line @typescript-eslint/camelcase
      epochs_number: number()
        .required(FORM_DICTIONARY.REQUIRED),

      // eslint-disable-next-line @typescript-eslint/camelcase
      learning_rate: number()
        .required(FORM_DICTIONARY.REQUIRED),

      // eslint-disable-next-line @typescript-eslint/camelcase
      loss_margin: number()
        .required(FORM_DICTIONARY.REQUIRED),

      // eslint-disable-next-line @typescript-eslint/camelcase
      num_workers: number()
        .required(FORM_DICTIONARY.REQUIRED)
    });

    const {
      handleSubmit,
      isSubmitting
    } = useForm({
      validationSchema: schema
    });

    const addNeural = handleSubmit(async(values) => {
      const {
        // eslint-disable-next-line @typescript-eslint/camelcase
        name,
        // eslint-disable-next-line @typescript-eslint/camelcase
        num_workers,
        // eslint-disable-next-line @typescript-eslint/camelcase
        loss_margin,
        // eslint-disable-next-line @typescript-eslint/camelcase
        learning_rate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        epochs_number,
        // eslint-disable-next-line @typescript-eslint/camelcase
        compare_threshold,
        // eslint-disable-next-line @typescript-eslint/camelcase
        batch_size
      } = values;
      dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.SAVE_NEW_NEURAL}`, {
        name: name,
        // eslint-disable-next-line @typescript-eslint/camelcase
        num_workers: +num_workers!,
        // eslint-disable-next-line @typescript-eslint/camelcase
        loss_margin: +loss_margin!,
        // eslint-disable-next-line @typescript-eslint/camelcase
        learning_rate: +learning_rate!,
        // eslint-disable-next-line @typescript-eslint/camelcase
        epochs_number: +epochs_number!,
        // eslint-disable-next-line @typescript-eslint/camelcase
        compare_threshold: +compare_threshold!,
        // eslint-disable-next-line @typescript-eslint/camelcase
        batch_size: +batch_size!
      });
      emit('close');
    });

    const handleClickClosePopUp = () => {
      emit('close');
    };

    return {
      addNeural,
      isSubmitting,
      handleClickClosePopUp
    };
  }
});
