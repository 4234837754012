<template>
  <div :class="`p-d-flex p-align-center p-field-checkbox ${isProcessing && 'ui-processing'}`">
    <InputSwitch
      :name="name"
      :modelValue="checkedState"
      @update:modelValue="change($event)"
      class="p-mr-2"
      :disabled="disabled"
    />
    <label>{{ label }}</label>
  </div>
</template>

<script src="./BaseSwitcher.ts" lang="ts"/>
<style src="./BaseSwitcher.scss" lang="scss"/>
