import { computed, defineComponent, PropType } from 'vue';
import { getTrainingOptions, TrainingStatus } from '@/store/modules/neural/types';

export default defineComponent({
  name: 'StatusBox',

  components: {},

  props: {
    status: {
      type: String as PropType<TrainingStatus>
    },
    text: {
      type: String
    },
    color: {
      type: String
    }
  },

  emits: ['clicked'],

  setup(props, { emit }) {
    const options = computed(() => getTrainingOptions(props.status || TrainingStatus.NOT_TRAINED));

    const clickHint = () => emit('clicked');

    return {
      options,
      TrainingStatus,
      clickHint
    };
  }
});
