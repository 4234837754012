<template>
  <BaseForm
    :handleSubmit='addNeural'
    class="ui-add-neural-form"
  >
    <template v-slot:form-content>
      <BaseInputText
        label="Имя"
        name="name"
        placeholder="Назовите нейросеть"
      />
      <div class="p-d-flex">
        <div class="p-d-flex p-flex-column p-mr-5">
          <BaseInputNumber
            name="batch_size"
            label="batch_size"
            :format="false"
            :step="1"
            :minFractionDigits="0"
            :maxFractionDigits="8"
            :min="0"
            :value="128"
          />
          <BaseInputNumber
            name="compare_threshold"
            label="compare_threshold"
            :format="false"
            :step=".1"
            :minFractionDigits="0"
            :maxFractionDigits="8"
            :min="0"
            :value="0.7"
          />
        </div>
        <div class="p-d-flex p-flex-column p-mr-5">
          <BaseInputNumber
            name="epochs_number"
            label="epochs_number"
            :format="false"
            :step="1"
            :minFractionDigits="0"
            :maxFractionDigits="8"
            :min="0"
            :value="5"
          />
          <BaseInputNumber
            name="learning_rate"
            label="learning_rate"
            :format="false"
            :step=".0001"
            :minFractionDigits="0"
            :maxFractionDigits="8"
            :min="0"
            :value=".0005"
          />
        </div>
        <div class="p-d-flex p-flex-column">
          <BaseInputNumber
            name="loss_margin"
            label="loss_margin"
            :format="false"
            :step="1"
            :minFractionDigits="0"
            :maxFractionDigits="8"
            :min="0"
            :value="1"
          />
          <BaseInputNumber
            name="num_workers"
            label="num_workers"
            :format="false"
            :step="1"
            :minFractionDigits="0"
            :min="0"
            :maxFractionDigits="8"
            :value="0"
          />
        </div>
      </div>
      <div class="split-line"></div>
      <div class="p-d-flex p-jc-end ui-button-group">
        <BaseButton
          :disabled="isSubmitting"
          outlined
          label="Отменить"
          type="reset"
          @clicked="handleClickClosePopUp"
          class="right-btn"
        />
        <BaseButton
          :disabled="isSubmitting"
          label="Сохранить"
          class="right-btn"
        />
      </div>
    </template>
  </BaseForm>
</template>

<script src="./FormAddNeural.ts" lang="ts"/>
<style src="./FormAddNeural.scss" lang="scss"/>
