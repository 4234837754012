<template>
  <BaseForm
    :handleSubmit="submitForm"
    class="ui-upload-form dataset-form"
  >
    <template v-slot:form-content>
      <BaseUploadFile
        name="file"
        label="Файл датасета"
      />
      <BaseInputText
        name="name"
        placeholder="Назовите датасет"
        type="text"
        label="Имя"
      />
      <div class="btn-wrapper">
        <BaseButton label="Добавить" />
      </div>
    </template>
  </BaseForm>
</template>

<script src="./FormAddDataset.ts" lang="ts"/>
<style src="./FormAddDataset.scss" lang="scss"/>
