<template>
  <div class="ui-progress-bar-block">
    <div class="status-line">
      <div :class="{ dark: isDarkLabel, label}">{{ label }}</div>
      <div class="percents">{{ value }}%</div>
    </div>
    <ProgressBar
      :showValue="false"
      :value="value"
    />
  </div>
</template>

<script src="./BaseProgressBar.ts" lang="ts"/>
<style src="./BaseProgressBar.scss" lang="scss" />
