import { TPageable } from '@/store/typeUtils';
import { TPaginator } from '@/store/modules/paginator/state';

export type TSaveNetworkParams = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/camelcase
  num_workers: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  loss_margin: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  learning_rate: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  epochs_number: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  compare_threshold: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  batch_size: number;
}

export type TTrainNetworkParams = {
  datasetId: number;
  networkId: number;
}

export type TSaveDataset = {
  name: string;
  file: any;
}

export type TShortNeuralUnit = {
  active: boolean;
  id: number;
  name: string;
}

export type TNeuralUnit = {
  active: boolean;
  createdAt: string;
  datasetName: string;
  id: number;
  name: string;
  paramsJson: {
    batch_size: number;
    compare_threshold: number;
    epochs_number: number;
    learning_rate: number;
    loss_margin: number;
    num_workers: number;
  };
  trainedAt: string;
  trainingDescription: string;
  trainingProgress: number;
  trainingStatus: TrainingStatus;
  updatedAt: string;
  isCandidate?: boolean;
  activationPercentage?: number;
}

export type TDatasetUnit = {
  createdAt: string;
  fileName: string;
  fileSize: number;
  id: number;
  name: string;
  updatedAt: string;
}

export type TShortDatasetUnit = {
  id: number;
  name: string;
}

export enum TrainingStatus {
  FAILED = 'FAILED',
  TRAINED = 'TRAINED',
  NOT_TRAINED = 'NOT_TRAINED',
  PROCESSING = 'PROCESSING'
}

export const getTrainingOptionsList = () => [
  {
    key: TrainingStatus.FAILED,
    label: 'ошибка обучения',
    class: 'error'
  },
  {
    key: TrainingStatus.TRAINED,
    label: 'обучена',
    class: 'success'
  },
  {
    key: TrainingStatus.NOT_TRAINED,
    label: 'не обучалась',
    class: 'not-trained'
  },
  {
    key: TrainingStatus.PROCESSING,
    label: 'обучается',
    class: 'processing'
  }
];

export const getTrainingOptions = (key: TrainingStatus) => {
  const item = getTrainingOptionsList().find(status => status.key === key);
  if (!item) throw Error(`Статус с ключом '${key}' не найден`);
  return item;
};

export type TNeuralState = {
  neuralList: TPageable<TNeuralUnit>;
  datasetList: TPageable<TDatasetUnit>;
  shortNeural: TShortNeuralUnit[];
  shortDataset: TShortDatasetUnit[];
  isLoadingShort: boolean;
  isLoadingDataset: boolean;
  isLoadingNeural: boolean;
  isLoadingActive: boolean;
  paginator: TPaginator;
  activeNetworks: TNeuralUnit[];
}
