<template>
  <BaseTable
    :columns="columns"
    :rows="rows"
    :is-loading="state.neural.isLoadingNeural"
    class-name="models-table neural-table"
  >
    <template #row="{ data }">
      <tr>
        <td>
          {{ data.cells.name }}
        </td>
        <td>
          <div class="p-d-flex">
            <div v-if="data.cells.datasetName">
              {{ data.cells.datasetName }}
            </div>
            <div class="p-d-flex p-ai-center">
              <BaseButton
                v-if="!data.cells.datasetName"
                outlined
                label="Обучить"
                @clicked="openTrainNeural(data.cells)"
                class="p-mr-2"
              />
            </div>
          </div>
        </td>
        <td>
          {{ data.cells.trainedAt ? getTrainedDate(data.cells.trainedAt) : '-' }}
        </td>
        <td>
          <StatusBox
            @clicked="openErrorModal(data.cells)"
            :status="data.cells.trainingStatus"
          />
        </td>
        <td>
          <StatusBox
            v-if="data.cells.isCandidate"
            color="#003F7C"
            text="идет переключение"
          />
          <StatusBox
            v-if="data.cells.active && !!candidateNetwork"
            color="#4D86BE"
            text="активная сеть"
          />
        </td>
        <td>
          <BaseSwitcher
            name="_"
            prevented
            @switched="beforeChange(data.cells)"
            :is-processing="data.cells.isCandidate"
            :is-checked="data.cells.isCandidate || data.cells.active"
            :disabled="data.cells.trainingStatus !== TrainingStatus.TRAINED || data.cells.active || !!candidateNetwork || state.neural.isLoadingActive"
          />
        </td>
        <td>
          <div
            @click="openParams(data.cells)"
            class="active-element"
          >
            Посмотреть
          </div>
        </td>
        <td>
          <div
            v-if="!data.cells.active && !data.cells.isCandidate"
            class="delete-button"
            @click="!state.neural.isLoadingNeural && openRemove(data.cells)"
          >
            <i class="pi pi-trash"></i>
            <span>Удалить нейросеть</span>
          </div>
        </td>
      </tr>
    </template>
  </BaseTable>
  <!--  TODO На один уровень-->
  <BasePopUp title="Выбор датасета" ref="popUpRefTrain">
    <template v-slot:content>
      <FormTrainNetwork
        :networkId="trainId.id"
        @submitted="toggleTrain"
        @onError="toggleTrain(true)"
      />
    </template>
  </BasePopUp>

  <BasePopUp ref="popUpRefRemove">
    <template v-slot:content>
      <div class="p-d-flex p-dir-col main-wrapper remove-neural-wrapper">
        <div class="circle-symbol">
          !
        </div>
        <div class="ui-title-large">
          Удалить нейросеть?
        </div>
        <div class="description">
          Восстановить нейросеть после удаления будет<br/>невозможно
        </div>
        <div class="btn-wrapper">
          <BaseButton label="Отмена" @clicked="toggle"/>
          <BaseButton outlined label="Удалить" @clicked="removeNeural"/>
        </div>
      </div>
    </template>
  </BasePopUp>

  <BasePopUp
    title="ТЕКУЩИЕ ПАРАМЕТРЫ НЕЙРОСЕТИ"
    ref="popUpRefParam"
  >
    <template v-slot:content>
      <div class="param-content">
        <div class="param-wrapper">
          <div class="title">nn_params:</div>
          <div>num_epochs: {{ openedNeural.paramsJson.epochs_number }}</div>
          <div>batch_size: {{ openedNeural.paramsJson.batch_size }}</div>
          <div>learning_rate: {{ openedNeural.paramsJson.learning_rate }}</div>
          <div>loss_margin: {{ openedNeural.paramsJson.loss_margin }}</div>
          <div>num_workers: {{ openedNeural.paramsJson.num_workers }}</div>
          <div>compare_threshold: {{ openedNeural.paramsJson.compare_threshold }}</div>
        </div>
      </div>
    </template>
  </BasePopUp>

  <BasePopUp ref="popUpRefErrorTraining">
    <template v-slot:content>
      <div class="p-d-flex p-dir-col main-wrapper remove-neural-wrapper">
        <div class="circle-symbol">
          ×
        </div>
        <div class="ui-title-large">
          Нейросеть обучена с ошибкой
        </div>
        <div class="description">
          Добавьте нейросеть заново или используйте<br/> другой датасет для обучения
        </div>
        <div class="p-d-flex p-dir-col column-wrapper">
          <BaseButton class="p-mb-3" label="Выбрать другой датасет" @clicked="openOtherDataset"/>
          <BaseButton outlined :label="`Повторить обучение с “${errorNeural.datasetName}”`" @clicked="reTrain"/>
        </div>
      </div>
    </template>
  </BasePopUp>

  <ChangeNeuralPopUp
    :value="newValue"
    ref="popUpRef"
  />
</template>

<script src="./NeuralTable.ts" lang="ts"/>
<style src="./NeuralTable.scss" lang="scss"/>
