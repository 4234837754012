import { defineComponent } from 'vue';
import { mixed, object, string } from 'yup';
import { useForm } from 'vee-validate';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import BaseForm from '@/components/BaseComponents/BaseForm/BaseForm.vue';
import BaseInputText from '@/components/BaseComponents/BaseInputText/BaseInputText.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BaseUploadFile from '@/components/BaseComponents/BaseUploadFile/BaseUploadFile.vue';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { NEURAL_ACTIONS_TYPE } from '@/store/names/action.name';
import appendToastMessage from '../../../utils/appendToastMessage';

export default defineComponent({
  name: 'FormAddDataset',

  components: {
    BaseForm,
    BaseUploadFile,
    BaseInputText,
    BaseButton
  },

  emits: ['closePopup', 'submitted'],

  setup(_, { emit }) {
    const { dispatch } = useStore();

    const schema = object({
      file: mixed()
        .required(FORM_DICTIONARY.REQUIRED_FILE),

      name: string()
        .max(20, FORM_DICTIONARY.MAX_20_SYMBOL)
        .required(FORM_DICTIONARY.REQUIRED)
    });

    const {
      handleSubmit,
      handleReset,
      isSubmitting,
      values
    } = useForm({ validationSchema: schema });

    const resetForm = () => {
      handleReset();
      emit('closePopup');
    };

    const submitForm = handleSubmit(async(values) => {
      const data = new FormData();
      data.append('file', values.file);
      try {
        await dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.SAVE_NEW_DATASET}`, {
          file: data,
          name: values.name
        });
        emit('submitted');
      } catch (e) {
        if (e.response?.status === 409) {
          appendToastMessage(FORM_DICTIONARY.DATASET_EXIST);
        } else {
          appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
        }
      }
    });

    return {
      resetForm,
      submitForm,
      values,
      isSubmitting
    };
  }
});
