<template>
  <BaseTable
    :columns="columns"
    :rows="rows"
    :is-loading="state.neural.isLoadingDataset"
    class-name="models-table dataset-table"
  >
    <template #row="{ data }">
      <tr>
        <td>
          {{ data.cells.name }}
        </td>
        <td>
          {{ data.cells.createdAt ? getTrainedDate(data.cells.createdAt) : '-' }}
        </td>
        <td>
          {{ (+data.cells.fileSize / 1000).toFixed(1) }} Кб
        </td>
        <td>
          <div
            class="delete-button"
            @click="openRemove(data.cells)"
          >
            <i class="pi pi-trash"></i>
            <span>Удалить датасет</span>
          </div>
        </td>
      </tr>
    </template>
  </BaseTable>

  <BasePopUp ref="popUpRefRemove">
    <template v-slot:content>
      <div class="p-d-flex p-dir-col main-wrapper">
        <div class="circle-symbol">
          !
        </div>
        <div class="ui-title-large">
          Удалить датасет?
        </div>
        <div class="btn-wrapper">
          <BaseButton label="Отмена" @clicked="toggleRemove"/>
          <BaseButton outlined label="Удалить" @clicked="removeDataset"/>
        </div>
      </div>
    </template>
  </BasePopUp>
</template>

<script src="./DatasetTable.ts" lang="ts"/>
<style src="./DatasetTable.scss" lang="scss"/>
