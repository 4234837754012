<template>
  <div class="p-fluid changer-wrapper">
    <BaseDropdown
      name="neural"
      :disabled="!!candidateNetwork"
      label="Активная нейросеть"
      prevented
      has-filter
      :value="state.neural.shortNeural"
      :prop-value="activeNetwork"
      @change="beforeChange($event)"
      :loading="state.neural.isLoadingShort || state.neural.isLoadingNeural || state.neural.isLoadingActive"
    />
    <template v-if="candidateNetwork">
      <div class="bar-title">Идет переключение на </div>
      <BaseProgressBar
        :label="candidateNetwork.name"
        :value="candidateNetwork.activationPercentage"
        isDarkLabel
      />
    </template>
  </div>
  <ChangeNeuralPopUp
    :value="newValue"
    ref="popUpRef"
  />
</template>

<script src="./ActiveNeuralChanger.ts" lang="ts"/>
<style src="./ActiveNeuralChanger.scss" lang="scss"/>
