<template>
  <BasePopUp ref="popUpRef">
    <template v-slot:content>
      <div class="p-d-flex p-dir-col main-wrapper remove-neural-wrapper">
        <div class="circle-symbol ui-warning">
          !
        </div>
        <div class="ui-title-large">
          СМЕНИТЬ НЕЙРОСЕТЬ?
        </div>
        <div class="btn-wrapper">
          <BaseButton label="Отмена" @clicked="toggle"/>
          <BaseButton outlined label="Сменить" @clicked="changeNeural"/>
        </div>
      </div>
    </template>
  </BasePopUp>
</template>

<script src="./ChangeNeuralPopUp.ts" lang="ts"/>
<style src="./ChangeNeuralPopUp.scss" lang="scss"/>
