<template>
  <InfoHeadForPage
    page-name="Нейросеть"
    :has-bottom-block="false"
  >
    <template v-slot:content-top>
      <div class="head-info-content">
        <div class="model-type-text ui-title-medium">
          управление нейросетями и датасетами
        </div>
      </div>
    </template>
  </InfoHeadForPage>
  <TabsNeural />
</template>

<script src="./PageNeural.ts" lang="ts"/>
<style src="./PageNeural.scss" lang="scss"/>
