import { computed, defineComponent, onMounted, watch } from 'vue';
import InfoHeadForPage from '@/components/InfoHeadForPage/InfoHeadForPage.vue';
import TabsNeural from '@/components/Neural/TabsNeural/TabsNeural.vue';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { NEURAL_ACTIONS_TYPE } from '@/store/names/action.name';
import { useStore } from 'vuex';
import { TNeuralUnit } from '@/store/modules/neural/types';

export default defineComponent({
  name: 'PageNeural',

  components: {
    InfoHeadForPage,
    TabsNeural
  },

  props: {
    pageName: {
      type: String,
      default: 'Все'
    }
  },

  setup() {
    const {
      dispatch,
      state
    } = useStore();

    const candidateNetwork = computed(
      (): TNeuralUnit => state.neural.activeNetworks.find((neural: TNeuralUnit) => neural.isCandidate)
    );

    const refetchCandidate = async() => {
      if (candidateNetwork.value) {
        setTimeout(async() => {
          await dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_ACTIVE_NEURAL}`);
          await refetchCandidate();
        }, 5000);
      } else {
        dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_NEURAL_LIST}`);
        dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_DATASET_LIST}`);
        dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_SHORTS_LISTS}`);
      }
    };

    watch(() => candidateNetwork.value?.id, async() => {
      if (candidateNetwork.value) {
        await refetchCandidate();
      }
    });

    onMounted(async() => {
      dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_NEURAL_LIST}`);
      dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_DATASET_LIST}`);
      dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_SHORTS_LISTS}`);
      dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_ACTIVE_NEURAL}`);
    });

    return {};
  }
});
