import { computed, defineComponent, ref } from 'vue';
import BaseTable from '@/components/BaseComponents/BaseTable/BaseTable.vue';
import { useStore } from 'vuex';
import { createdAt } from '@/components/Model/ModelCards/utils/createdAt';
import { getModelValue } from '@/components/Model/ModelCards/utils/getModelValue';
import { TNeuralUnit, TrainingStatus } from '@/store/modules/neural/types';
import BaseSwitcher from '@/components/BaseComponents/BaseSwitcher/BaseSwitcher.vue';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { NEURAL_ACTIONS_TYPE } from '@/store/names/action.name';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BasePopUp from '@/components/BaseComponents/BasePopUp/BasePopUp.vue';
import ChangeNeuralPopUp from '@/components/Neural/ChangeNeuralPopUp/ChangeNeuralPopUp.vue';
import moment from 'moment/moment';
import FormTrainNetwork from '@/components/Forms/FormTrainNetwork/FormTrainNetwork.vue';
import Hint from '@/components/Hint/Hint.vue';
import StatusBox from '@/components/Neural/StatusBox/StatusBox.vue';
import appendToastMessage from '@/utils/appendToastMessage';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import { NEURAL_MUTATIONS_TYPE } from '@/store/names/mutations.names';

export default defineComponent({
  name: 'NeuralTable',

  components: {
    BaseTable,
    BaseSwitcher,
    Hint,
    BaseButton,
    ChangeNeuralPopUp,
    BasePopUp,
    FormTrainNetwork,
    StatusBox
  },

  setup() {
    const { state, dispatch, commit } = useStore();

    const columns = computed(() => {
      return [
        {
          title: 'Нейросеть',
          colspan: 1
        },
        {
          title: 'Датасет',
          colspan: 1
        },
        {
          title: 'Последнее обучение',
          colspan: 1
        },
        {
          title: 'Статус обучения',
          colspan: 1
        },
        {
          title: '',
          colspan: 1
        },
        {
          title: 'Активность',
          colspan: 1
        },
        {
          title: 'Параметры',
          colspan: 1
        },
        {
          title: '',
          colspan: 1
        }
      ];
    });

    const rows = computed(() => {
      return state.neural.neuralList.content.length ? state.neural.neuralList.content?.map((item: TNeuralUnit) => {
        return { cells: item };
      }) : [];
    });

    const candidateNetwork = computed(
      (): TNeuralUnit => state.neural.activeNetworks.find((neural: TNeuralUnit) => neural.isCandidate)
    );

    const getTrainedDate = (date: string) => {
      return moment(date).format('DD.MM.YY');
    };

    //
    const popUpRef = ref();
    const newValue = ref();
    const beforeChange = (neural: TNeuralUnit) => {
      newValue.value = neural;
      popUpRef.value.toggle();
    };

    //
    const popUpRefParam = ref();
    const openedNeural = ref();
    const openParams = (neural: TNeuralUnit) => {
      openedNeural.value = neural;
      popUpRefParam.value.handleModal();
    };

    //
    const popUpRefRemove = ref();
    const removeId = ref();
    const openRemove = (neural: TNeuralUnit) => {
      removeId.value = neural;
      popUpRefRemove.value.handleModal();
    };
    const toggle = () => {
      popUpRefRemove.value.handleModal();
    };
    const removeNeural = async() => {
      try {
        toggle();
        await dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.DELETE_NEURAL}`, removeId.value.id);
      } catch (e) {
        console.error(e);
      }
    };

    //
    const hasProcessing = computed(() => !!state.neural.neuralList.content.find(
      (item: TNeuralUnit) => item.trainingStatus === TrainingStatus.PROCESSING)
    );
    const watchStatus = () => {
      const timer = setInterval(async() => {
        await dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_NEURAL_LIST}`);
        if (!hasProcessing.value) {
          await dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_SHORTS_LISTS}`);
        } clearInterval(timer);
      }, 5000);
    };

    //
    const popUpRefTrain = ref();
    const trainNeural = ref();
    const openTrainNeural = (neural: TNeuralUnit) => {
      trainNeural.value = neural;
      popUpRefTrain.value.handleModal();
    };
    const toggleTrain = (hasError = false) => {
      popUpRefTrain.value.handleModal();
      if (hasError) return;
      watchStatus();
    };

    //
    const popUpRefErrorTraining = ref();
    const errorNeural = ref();
    const openErrorModal = (neural: TNeuralUnit) => {
      errorNeural.value = neural;
      popUpRefErrorTraining.value.handleModal();
    };
    const toggleError = () => {
      popUpRefErrorTraining.value.handleModal();
    };
    const openOtherDataset = () => {
      toggleError();
      openTrainNeural(errorNeural.value);
    };
    const reTrain = async() => {
      try {
        await dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.TRAIN_NEURAL}`, {
          datasetId: errorNeural.value.datasetId,
          networkId: errorNeural.value.id
        });
        await dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_SHORTS_LISTS}`);
      } catch (e) {
        if (e?.response?.status === 423) {
          appendToastMessage(FORM_DICTIONARY.SERVICE_BUSY);
          commit(`${MODULES_NAMES.NEURAL}/${NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL}`, false);
        } else {
          appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
        }
        console.error(e);
      }
    };

    return {
      candidateNetwork,
      errorNeural,
      reTrain,
      openOtherDataset,
      toggleError,
      popUpRefErrorTraining,
      openErrorModal,
      popUpRefTrain,
      hasProcessing,
      trainId: trainNeural,
      toggleTrain,
      openTrainNeural,
      removeNeural,
      toggle,
      beforeChange,
      openParams,
      openRemove,
      openedNeural,
      popUpRefParam,
      popUpRefRemove,
      getTrainedDate,
      TrainingStatus,
      newValue,
      getModelValue,
      state,
      popUpRef,
      createdAt,
      columns,
      rows
    };
  }
});
