<template>
  <label
    :for="`textarea-${name}`"
    :class="`${disabled && 'ui-disabled' } ${!!errorMessage && 'ui-error-label' } ui-textarea-label`"
  >
    {{ label }}
  </label>
  <Textarea
    :id="`textarea-${name}`"
    :class="`${!!errorMessage && 'p-invalid ui-error-input'}  ui-textarea`"
    :value="inputValue"
    @input="handleInput"
    :placeholder="placeholder"
  />
  <BaseInlineMessage
    v-if="hasError"
    :message-text="errorMessage"
  />
</template>

<script src="./BaseTextArea.ts" lang="ts"/>
<style src="./BaseTextArea.scss" lang="scss"/>
