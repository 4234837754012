<template>
  <BaseButton
    label="+ Добавить нейросеть"
    @clicked="togglePopUp"
  />
  <BasePopUp
    title="СОЗДАТЬ НОВУЮ НЕЙРОСЕТЬ"
    ref="popUpRef"
  >
    <template v-slot:content>
      <FormAddNeural @close="togglePopUp" />
    </template>
  </BasePopUp>
</template>

<script src="./AddNeuralButton.ts" lang="ts"/>
<style src="./AddNeuralButton.scss" lang="scss"/>
