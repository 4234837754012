import { defineComponent } from 'vue';
import { object, mixed } from 'yup';
import { useForm } from 'vee-validate';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import BaseForm from '@/components/BaseComponents/BaseForm/BaseForm.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BaseDropdown from '@/components/BaseComponents/BaseDropdown/BaseDropdown.vue';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { NEURAL_ACTIONS_TYPE } from '@/store/names/action.name';
import appendToastMessage from '../../../utils/appendToastMessage';
import { NEURAL_MUTATIONS_TYPE } from '../../../store/names/mutations.names';

export default defineComponent({
  name: 'FormTrainNetwork',

  components: {
    BaseForm,
    BaseDropdown,
    BaseButton
  },

  props: {
    networkId: {
      type: Number,
      required: true
    }
  },

  emits: ['submitted', 'onError'],

  setup(props, { emit }) {
    const { state, dispatch, commit } = useStore();
    const schema = object({
      dataset: mixed()
        .required(FORM_DICTIONARY.REQUIRED)
    });

    const {
      handleSubmit,
      isSubmitting,
      values
    } = useForm({ validationSchema: schema });

    const submitForm = handleSubmit(async(values) => {
      try {
        await dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.TRAIN_NEURAL}`, {
          datasetId: values.dataset.id,
          networkId: props.networkId
        });
        emit('submitted');
      } catch (e) {
        if (e.response?.status === 423) {
          appendToastMessage(FORM_DICTIONARY.SERVICE_BUSY);
          commit(`${MODULES_NAMES.NEURAL}/${NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL}`, false);
        } else {
          appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
        }
        console.error(e);
        emit('onError');
      }
    });

    return {
      state,
      submitForm,
      values,
      isSubmitting
    };
  }
});
