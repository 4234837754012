import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Hint',

  setup() {
    return {
    };
  }
});
