<template>
  <TabView>
    <TabPanel header="НЕЙРОСЕТИ">
      <div class="p-d-flex p-align-start p-mb-5 p-justify-between">
        <div>
          <ActiveNeuralChanger/>
        </div>
        <div class="add-btn-wrapper">
          <Hint
            v-tooltip.left="'Чтобы добавить новую нейросеть сделайте X, Y, Z. Обучите ее датасетом'"
          />
          <AddNeuralButton/>
        </div>
      </div>
      <NeuralTable/>
      <BasePaginator
        :total-records="state.neural.neuralList?.totalElements || 0"
        @changed="onChangeNeural($event)"
        :rows-per-page-options="defaultPaginatorSettings.selectSize"
        :size-rows="state.paginator.paginator.size"
        :first-page-prop="state.paginator.paginator.page"
        :total-pages="state.neural.neuralList.totalPages"
      />
    </TabPanel>
    <TabPanel header="ДАТАСЕТЫ">
      <div class="p-d-flex p-justify-between p-mt-3">
        <DatasetTable/>
        <div>
          <BaseButton
            label="+ Добавить датасет"
            @clicked="togglePopUp"
          />
        </div>
      </div>
      <BasePaginator
        :total-records="state.neural.datasetList?.totalElements || 0"
        @changed="onChangeDataset($event)"
        :rows-per-page-options="defaultPaginatorSettings.selectSize"
        :size-rows="state.neural.paginator.size"
        :first-page-prop="state.neural.paginator.page"
        :total-pages="state.neural.neuralList.totalPages"
      />
    </TabPanel>
  </TabView>
  <BasePopUp
    title="Добавить датасет"
    ref="popUpRef"
  >
    <template v-slot:content>
      <FormAddDataset @submitted="togglePopUp"/>
    </template>
  </BasePopUp>
</template>

<script src="./TabsNeural.ts" lang="ts"/>
<style src="./TabsNeural.scss" lang="scss"/>
