import { defineComponent } from 'vue';
import ProgressBar from 'primevue/progressbar';

export default defineComponent({
  name: 'BaseProgressBar',
  components: { ProgressBar },

  props: {
    value: {
      type: Number,
      default: 0
    },
    label: {
      type: String
    },
    isDarkLabel: {
      type: Boolean,
      default: false
    }
  }
});
