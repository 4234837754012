import { defineComponent, ref, watch } from 'vue';
import InputSwitch from 'primevue/inputswitch';
import { useField } from 'vee-validate';

export default defineComponent({
  name: 'BaseSwitcher',

  components: {
    InputSwitch
  },

  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prevented: {
      type: Boolean,
      default: false
    },
    isProcessing: {
      type: Boolean,
      default: false
    }
  },

  emits: ['switched'],

  setup(props, { emit }) {
    const checkedState = ref(props.isChecked);

    const { handleChange } = useField(props.name, undefined,
      {
        type: 'checkbox',
        initialValue: props.isChecked
      });

    const change = (e: boolean) => {
      emit('switched', e);
      if (props.prevented) return;
      handleChange(e);
      checkedState.value = e;
    };

    watch(() => props.isChecked, () => {
      checkedState.value = props.isChecked;
    });

    return {
      change,
      checkedState
    };
  }
});
