import { computed, defineComponent, ref } from 'vue';
import BaseTable from '@/components/BaseComponents/BaseTable/BaseTable.vue';
import { useStore } from 'vuex';
import { createdAt } from '@/components/Model/ModelCards/utils/createdAt';
import { getModelValue } from '@/components/Model/ModelCards/utils/getModelValue';
import { TDatasetUnit } from '@/store/modules/neural/types';
import moment from 'moment';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { NEURAL_ACTIONS_TYPE } from '@/store/names/action.name';
import BasePopUp from '@/components/BaseComponents/BasePopUp/BasePopUp.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';

export default defineComponent({
  name: 'DatasetTable',

  components: {
    BaseTable,
    BasePopUp,
    BaseButton
  },

  setup() {
    const { state, dispatch } = useStore();

    const columns = computed(() => {
      return [
        { title: 'Датасет', colspan: 1 },
        { title: 'Дата создания', colspan: 1 },
        { title: 'Размер данных', colspan: 1 },
        { title: '', colspan: 1 }
      ];
    });

    const rows = computed(
      () => state.neural.datasetList.content.length
        ? state.neural.datasetList.content?.map((item: TDatasetUnit) => ({ cells: item }))
        : []
    );

    const getTrainedDate = (date: string) => {
      return moment(date).format('DD.MM.YY');
    };

    const popUpRefRemove = ref();
    const removeId = ref();
    const openRemove = (dataset: TDatasetUnit) => {
      removeId.value = dataset;
      popUpRefRemove.value.handleModal();
    };
    const toggleRemove = () => {
      popUpRefRemove.value.handleModal();
    };
    const removeDataset = async() => {
      try {
        toggleRemove();
        await dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.DELETE_DATASET}`, removeId.value.id);
      } catch (e) {
        console.error(e);
      }
    };

    return {
      openRemove,
      toggleRemove,
      popUpRefRemove,
      removeDataset,
      getTrainedDate,
      getModelValue,
      state,
      createdAt,
      columns,
      rows
    };
  }
});
