import { computed, defineComponent } from 'vue';
import Textarea from 'primevue/textarea';
import { useField } from 'vee-validate';
import BaseInlineMessage from '@/components/BaseComponents/BaseInlineMessage/BaseInlineMessage.vue';

export default defineComponent({
  name: 'BaseTextArea',

  components: {
    Textarea,
    BaseInlineMessage
  },

  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean
    },
    placeholder: {
      type: String
    }
  },

  setup(props) {
    const {
      errorMessage,
      handleBlur,
      value: inputValue,
      handleInput
    } = useField(props.name, undefined, {});

    const hasError = computed(() => {
      return (!!errorMessage.value && errorMessage.value.toString() !== ' ');
    });

    return {
      hasError,
      errorMessage,
      handleBlur,
      inputValue,
      handleInput
    };
  }
});
