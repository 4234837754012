import { defineComponent, ref } from 'vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BasePopUp from '@/components/BaseComponents/BasePopUp/BasePopUp.vue';
import FormAddNeural from '@/components/Forms/FormAddNeural/FormAddNeural.vue';

export default defineComponent({
  name: 'AddNeuralButton',

  components: {
    BaseButton,
    BasePopUp,
    FormAddNeural
  },

  setup() {
    const popUpRef = ref();

    const togglePopUp = () => {
      return popUpRef?.value?.handleModal();
    };

    return { togglePopUp, popUpRef };
  }
});
