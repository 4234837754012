import { computed, defineComponent, ref } from 'vue';
import BaseDropdown from '@/components/BaseComponents/BaseDropdown/BaseDropdown.vue';
import BasePopUp from '@/components/BaseComponents/BasePopUp/BasePopUp.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import { useStore } from 'vuex';
import { TNeuralUnit } from '@/store/modules/neural/types';
import ChangeNeuralPopUp from '@/components/Neural/ChangeNeuralPopUp/ChangeNeuralPopUp.vue';
import BaseProgressBar from '@/components/BaseComponents/BaseProgressBar/BaseProgressBar.vue';

export default defineComponent({
  name: 'ActiveNeuralChanger',

  components: {
    BaseDropdown,
    BasePopUp,
    BaseButton,
    ChangeNeuralPopUp,
    BaseProgressBar
  },

  setup() {
    const { state } = useStore();
    const activeNetwork = computed(
      () => state.neural.shortNeural.find((neural: TNeuralUnit) => neural.active)
    );

    const candidateNetwork = computed(
      (): TNeuralUnit => state.neural.activeNetworks.find((neural: TNeuralUnit) => neural.isCandidate)
    );

    const popUpRef = ref();
    const newValue = ref();
    const beforeChange = (e: any) => {
      newValue.value = e.value;
      popUpRef.value.toggle();
    };

    return {
      state,
      candidateNetwork,
      newValue,
      beforeChange,
      activeNetwork,
      popUpRef
    };
  }
});
