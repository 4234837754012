<template>
  <div
    :class="`ui-status-box ${options.class}`"
    :style="{background: color}"
  >
    <div>{{ text || options.label }}</div>
    <div
      v-if="options.key === TrainingStatus.FAILED"
      class="hint"
      @click="clickHint"
    >подробнее
    </div>
  </div>
</template>

<script src="./StatusBox.ts" lang="ts"/>
<style src="./StatusBox.scss" lang="scss"/>
