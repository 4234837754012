import { defineComponent, PropType, ref } from 'vue';
import BaseDropdown from '@/components/BaseComponents/BaseDropdown/BaseDropdown.vue';
import BasePopUp from '@/components/BaseComponents/BasePopUp/BasePopUp.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { NEURAL_ACTIONS_TYPE } from '@/store/names/action.name';
import { TNeuralUnit } from '@/store/modules/neural/types';

export default defineComponent({
  name: 'ChangeNeuralPopUp',

  components: {
    BaseDropdown,
    BasePopUp,
    BaseButton
  },

  props: {
    value: {
      type: Object as PropType<TNeuralUnit>
    }
  },

  setup(props) {
    const { dispatch } = useStore();
    const popUpRef = ref();

    const toggle = () => {
      popUpRef.value.handleModal();
    };

    const changeNeural = async() => {
      popUpRef.value.handleModal();
      await dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.ACTIVATE_NEURAL}`, props.value?.id);
      dispatch(`${MODULES_NAMES.NEURAL}/${NEURAL_ACTIONS_TYPE.GET_ACTIVE_NEURAL}`);
    };

    return {
      changeNeural,
      toggle,
      popUpRef
    };
  }
});
